<template>
    <div class="sidebarRight">
        <div class="item">
            <div class="list">
                <img src="../assets/img/weixin.png" alt="">
                <div>微信咨询</div>
            </div>
            <div class="wewixin">
                <img src="../assets/img/mimogzh.png" alt="">
                <div>微信扫码添加客服</div>
            </div>
        </div>
        <div class="item">
            <div class="list">
                <img src="../assets/img/telephone.png" alt="">
                <div>400 咨询</div>
            </div>
            <div class="consult">
                <!-- <div>电话联系</div>
          <div class="link"></div> -->
                <div> <img src="../assets/img/phone.png" alt="">
                    <div class="phone">400-186-1200</div>
                </div>
            </div>
        </div>
        <div class="item">
            <div class="list">
                <img src="../assets/img/guanzhu.png" alt="">
                <div>关注我们</div>
            </div>
            <div class="publicAccount">
                <img src="../assets/img/mimogzh.png" alt="">
                <div>微信扫码关注公众号</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
}
</script>
<style lang="scss" scoped>
.sidebarRight {
    z-index: 1;
    position: fixed;
    right: 1%;
    top: 50%;
    width: 70px;
    height: 160px;
    border-radius: 10px;
    background: rgba(0, 0, 0, .4);
    padding: 10px 0;

    .item {
        color: aliceblue;
        font-size: 10px;
        height: 51px;
        cursor: pointer;
        position: relative;

        &:hover .wewixin {
            display: block;
        }

        &:hover .consult {
            display: block;
        }

        &:hover .publicAccount {
            display: block;
        }

        .wewixin {
            display: none;
            padding: 5px;
            position: absolute;
            top: 20px;
            left: -140px;
            width: 120px;
            height: 120px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

            :first-child {
                width: 90px;
                height: 90px;
            }

            :last-child {
                font-size: 12px;
                color: #333333;
            }
        }

        .consult {
            display: none;
            padding: 5px;
            position: absolute;
            top: -10px;
            left: -140px;
            width: 120px;
            height: 60px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);
            color: #000;

            :first-child {
                text-align: left;
            }

            .link {
                width: 100%;
                height: 1px;
                margin-top: 5px;
                background-color: #f5f5f5;
            }

            :last-child {
                margin-top: 20px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: space-around;

                img {
                    width: 24px;
                    height: 24px;
                }

                .phone {
                    margin: 0;
                }
            }
        }

        .publicAccount {
            display: none;
            padding: 5px;
            position: absolute;
            top: -85px;
            left: -140px;
            width: 120px;
            height: 120px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04);

            :first-child {
                width: 90px;
                height: 90px;
            }

            :last-child {
                font-size: 12px;
                color: #333333;
            }
        }

        .list {
            padding-top: 7px;

            img {
                width: 25px;
                height: 25px;
            }
        }
    }
}
</style>
