<template>
  <div class="about">
    <Navigation />
    <div style="height: 2000px;"></div>
    <bottom />
  </div>
</template>
<script>
import Navigation from '@/components/Navigation.vue'
import bottom from '@/components/bottom.vue'
export default {
  components: {
    Navigation,
    bottom
  }
}
</script>
